import { render, staticRenderFns } from "./Notfund.vue?vue&type=template&id=bad7d466&scoped=true"
import script from "./Notfund.vue?vue&type=script&lang=js"
export * from "./Notfund.vue?vue&type=script&lang=js"
import style0 from "./Notfund.vue?vue&type=style&index=0&id=bad7d466&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bad7d466",
  null
  
)

export default component.exports