<template>
  <div class="container">
    <img class="empty" src="@/assets/images/empty.png" alt="empty" />
    <p class="passage">对不起，您访问的页面不存在~</p>
    <p class="passage passage2">Page not found:{{ path }}</p>
    <RouterLink class="back" to="/">Go Back Home</RouterLink>
  </div>
</template>
<script>
export default {
  data() {
    return {
      path: "/",
    };
  },
  mounted() {
    this.path = window.location.pathname;
  },
};
</script>
<style scoped>
.container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 200px;
}
.empty {
  width: 197px;
  height: 128px;
  margin: 20px 0;
}
.passage {
  font-size: 22px;
  color: #504f4f;
  margin: 10px 0;
}
.passage2 {
  font-family: "Poppins-Regular", sans-serif;
}
.back {
  border: 0;
  margin: 20px;
  text-transform: uppercase;
  font-size: 20px;
  font-family: "Poppins-Bold", sans-serif;
  padding: 15px 50px;
  border-radius: 50px;
  color: white;
  outline: none;
  position: relative;
  background-image: linear-gradient(to right, #1d61e5, #5dd8ee);
  box-shadow: 0 4px 15px 0 rgba(78, 170, 241, 0.75);
  text-decoration: none;
  &:hover:before {
    background-position: left bottom;
  }
  &::before {
    content: "";
    display: block;
    background: linear-gradient(
      to left,
      rgba(255, 255, 255, 0) 50%,
      rgba(255, 255, 255, 0.4) 50%
    );
    background-size: 210% 100%;
    background-position: right bottom;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    border-radius: 50px;
    transition: all 1s;
    -webkit-transition: all 0.5s;
  }
}
</style>
